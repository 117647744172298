<template>
  <v-data-table :headers="table.headers" :items="items" hide-default-footer class="elevation-1">
    <template #item="row">
      <tr>
        <td>{{ $t(`hr.travel.travel_detail_type_enum.${row.item.travelDetailType}`) }}</td>
        <td>{{ $t(`hr.travel.travel_detail_subtype_enum.${row.item.travelDetailSubType}`) }}</td>
        <td>{{ row.item.beginDate | formattedDateTimeMinute }}</td>
        <td>{{ row.item.endDate | formattedDateTimeMinute }}</td>
        <td class="text-center">
          <v-icon :color="row.item.isRentCar ? 'green' : 'red'">{{ row.item.isRentCar ? "mdi-check" : "mdi-close" }}</v-icon>
        </td>
        <td class="text-center">
          <v-icon :color="row.item.isShuttle ? 'green' : 'red'">{{ row.item.isShuttle ? "mdi-check" : "mdi-close" }}</v-icon>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    name: "TravelDetailTable",
    props: {
      items: Array
    },
    data: vm => ({
      table: {
        headers: [
          {
            text: vm.$t("hr.travel.detail_type"),
            align: "left",
            sortable: false,
            value: "travelDetailType"
          },
          {
            text: vm.$t("hr.travel.detail_subtype"),
            align: "left",
            sortable: false,
            value: "travelDetailSubType"
          },
          {
            text: vm.$t("hr.travel.begin_date"),
            align: "left",
            sortable: false,
            value: "beginDate"
          },
          {
            text: vm.$t("hr.travel.end_date"),
            align: "left",
            sortable: false,
            value: "endDate"
          },
          {
            text: vm.$t("hr.travel.is_rent_car"),
            align: "center",
            sortable: false,
            value: "rentCar"
          },
          {
            text: vm.$t("hr.travel.is_shuttle"),
            align: "center",
            sortable: false,
            value: "shuttle"
          }
        ]
      }
    })
  };
</script>

<style></style>
